import { NotificationService } from 'src/app/core/services/notification.service';
import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin-panel-header',
  templateUrl: './admin-panel-header.component.html',
  styleUrls: ['./admin-panel-header.component.scss']
})
export class AdminPanelHeaderComponent implements OnInit {

  constructor(public router: Router, private notify: NotificationService) { }

  userData: any = this.router.routerState.snapshot.url.split('/')[1] == "company-admin"
    ? JSON.parse(localStorage.getItem('accessUser')) || JSON.parse(sessionStorage.getItem('accessUser'))
    : JSON.parse(localStorage.getItem('accessSuperAdmin')) || JSON.parse(sessionStorage.getItem('accessSuperAdmin'));

  isAdmin: boolean = false;
  isCompanyAdmin: boolean = false;

  ngOnInit() {

    if (this.userData && this.userData.userType == 1) {
      this.isAdmin = true;
    } else if (this.userData && this.userData.userType == 3) {
      this.isCompanyAdmin = true;
    } else {
      this.notify.bProfileData.subscribe(result => {
        if (result) {
          this.userData = JSON.parse(result)
        }
      })
    }
  }

  openDrawer(): void {
    UtilityService.DRAWER_REF.toggle();
  }

  /**
   * @name handleLogout
   * @description Logging out user
   */
  handleLogout() {
    if (this.isAdmin) {
      localStorage.removeItem('accessSuperAdmin')
      sessionStorage.removeItem('accessSuperAdmin')
      this.router.navigate(['/admin/sign-in'])
    } else if (this.isCompanyAdmin) {
      localStorage.removeItem('accessUser')
      sessionStorage.removeItem('accessUser')
      this.router.navigate(['/company-admin/sign-in'])
    } else {
      localStorage.removeItem('memberUser')
      sessionStorage.removeItem('memberUser')
      this.router.navigate(['/company-member/sign-in'])
    }
  }
}
