import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() { }

  signin() {
    this.router.navigate(['company-admin/sign-in', {}])
  }

  membersignin() {
    this.router.navigate(['company-member/sign-in', {}])
  }

}
