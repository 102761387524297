import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor() {
    console.group = console.info = console.log = console.warn = console.error = () => { };
  }

  title = 'aklantic';
}
