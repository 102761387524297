import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { AdminPanelHeaderComponent } from './components/admin-panel-header/admin-panel-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserLayoutComponent } from './components/user-layout/user-layout.component';

@NgModule({
  declarations: [AdminLayoutComponent, HeaderComponent, FooterComponent, AdminPanelHeaderComponent, UserLayoutComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule
  ],
  exports: [AdminLayoutComponent, MatSidenavModule, HeaderComponent, FooterComponent ]
})
export class CoreModule { }
