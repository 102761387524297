import { Component, OnInit, HostListener, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  isCompanyAdmin: boolean = false;

  constructor(private utilityService: UtilityService, private router: Router) {
    let routeArr = this.router.url.split('/');
    let Roletype = routeArr ? routeArr[1] : '';

    if (Roletype == 'admin') {
      this.isCompanyAdmin = true;
    } else {
      this.isCompanyAdmin = false;
    }

    this.isDrawerOpen = true;
    this.setDrawerConfigs();
  }

  ngOnInit(): void {

    if (window.innerWidth >= 992) {
      this.dynamicMode = 'side';
    }
  }

  department() {
    this.router.navigate(['company-admin/department', {}])
  }
  videos() {
    this.router.navigate(['company-admin/department/videos', {}])
  }

  @ViewChildren('drawer') drawer: any;
  dynamicMode: any;
  isDrawerOpen: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDrawerConfigs();
  }

  setDrawerConfigs(): void {
    if (window.innerWidth <= 1170) {
      this.isDrawerOpen = false;
      this.dynamicMode = 'over';
    } else {
      this.dynamicMode = 'side';
    }
  };

  ngAfterViewInit(): void {
    UtilityService.DRAWER_REF = this.drawer.first;
  }

  isActive(): boolean {
    let slab_url = location.pathname.split('/').slice(0, location.pathname.split('/').length - 1).join("/")
    if (location.pathname == '/admin/company-list' || slab_url == '/admin/slabs') {
      return true
    } else {
      return false
    }
  }
}
