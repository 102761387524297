import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DeleteModalModule } from './main/department/members/delete-modal/delete-modal.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './core/services/interceptor/http-interceptor.service';
import { AuthGuard, LoginGuard, SuperAdminLoginGuard, SuperAdminGuard } from './core/guard/auth.guard';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBarModule, MatSnackBarConfig } from '@angular/material';
import { PortalModule } from '@angular/cdk/portal';
import { Router } from '@angular/router';

export const snackBarConfig: MatSnackBarConfig = {
  announcementMessage: "Error", duration: 15000
}

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    NgxMaterialTimepickerModule,
    DeleteModalModule,
    HttpClientModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    PortalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router) {
        return new HttpInterceptorService(router);
      },
      useClass: HttpInterceptorService,
      multi: true,
      deps: [Router]
    },
    AuthGuard,
    LoginGuard,
    SuperAdminGuard,
    SuperAdminLoginGuard
  ],
  entryComponents: [
    MatSpinner,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
