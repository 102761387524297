import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './core/components/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './core/components/admin-layout/admin-layout.component';
import { UserLayoutComponent } from './core/components/user-layout/user-layout.component';
import { AuthGuard, LoginGuard, SuperAdminGuard, SuperAdminLoginGuard } from './core/guard/auth.guard';

const routes: Routes = [
  {
    path: "company-admin",
    component: AdminLayoutComponent,
    children: [
      {
        path: "department",
        loadChildren: "./main/department/department.module#DepartmentModule",
      },
      {
        path: "my-profile",
        loadChildren: "./main/my-profile/my-profile.module#MyProfileModule",
      },
      {
        path: "settings",
        loadChildren: "./main/settings/settings.module#SettingsModule",
      },
      {
        path: "schedule-interviews",
        loadChildren: "./main/department/department.module#DepartmentModule",
      },
      {
        path: "slabs",
        loadChildren: "./main/slabs/slabs.module#SlabsModule",
      },
    ],
    canActivate: [LoginGuard]
  },

  {
    path: "company-member",
    component: UserLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: "./user/user.module#UserModule",
      }
    ],
  },

  {
    path: 'home',
    children: [
      {
        path: '',
        loadChildren: './main/home/home.module#HomeModule'
      }
    ]
  },

  {
    path: "",
    pathMatch: "full",
    children: [
      {
        path: '',
        loadChildren: './main/home/home.module#HomeModule'
      }
    ],
    canActivate: [AuthGuard]
  },

  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: './main/authentication/authentication.module#AuthenticationModule'
      }
    ]
  },

  {
    path: 'admin',
    canActivate: [SuperAdminLoginGuard],
    children: [
      {
        path: '',
        loadChildren: './super-admin/super-admin.module#SuperAdminModule',
      },
    ]
  },

  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [SuperAdminGuard],
    children: [
      {
        path: 'company-list',
        loadChildren: './super-admin/company-list/company-list.module#SuperAdminModule',
      },

      {
        path: 'company-archived',
        loadChildren: './super-admin/company-archived/company-archived.module#SuperAdminModule',
      },

      {
        path: 'slabs/:id',
        loadChildren: './super-admin/slab/slab.module#SuperAdminModule',
      },

      {
        path: 'company-billing',
        loadChildren: './super-admin/company-billing/company-billing.module#SuperAdminModule',
      },
    ]
  },

];

@NgModule({
  declarations: [AuthLayoutComponent],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
