import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const isCompanyAdmin = route.url.length && route.url[0].path == 'company-admin' ? true : false

        const user = isCompanyAdmin
            ? (JSON.parse(localStorage.getItem('accessUser')) || JSON.parse(sessionStorage.getItem('accessUser')))
            : (JSON.parse(localStorage.getItem('memberUser')) || JSON.parse(sessionStorage.getItem('memberUser')))

        if (user && user.token) {
            if (user.user_type == 'member') {
                this.router.navigate(['/company-member/company-list']);
            } else {
                this.router.navigate(['/company-admin/department/dashboard']);
            }
            return true;
        }
        this.router.navigate(['/home']);
        return false;
    }
}

@Injectable()
export class SuperAdminGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        console.log('SuperAdminGuard')

        const admin = JSON.parse(localStorage.getItem('accessSuperAdmin')) || JSON.parse(sessionStorage.getItem('accessSuperAdmin'))
        if (admin && admin.token) {
            // this.router.navigate(['/admin/company-list']);
            return true;
        }
        this.router.navigate(['/admin/sign-in']);
        return false;
    }
}

@Injectable()
export class SuperAdminLoginGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        console.log('SuperAdminLoginGuard')

        const admin = JSON.parse(localStorage.getItem('accessSuperAdmin')) || JSON.parse(sessionStorage.getItem('accessSuperAdmin'))
        if (!admin) {
            return true;
        }
        this.router.navigate(['/admin/company-list']);
        return false;
    }
}

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        console.log('LoginGuard')

        const isCompanyAdmin = route.url.length ? route.url[0].path == 'company-admin' ? true : false : true;
        const user = isCompanyAdmin
            ? (JSON.parse(localStorage.getItem('accessUser')) || JSON.parse(sessionStorage.getItem('accessUser')))
            : (JSON.parse(localStorage.getItem('memberUser')) || JSON.parse(sessionStorage.getItem('memberUser')))

        if (user && (user || {}).token) return true;
        this.router.navigate(['/company-admin/sign-in']);

        // if(state.url.search("user")){
        //     this.router.navigate(['/authentication/sign-in'], { queryParams: { red_type: 'user' } });
        // }
        // else {
        //     this.router.navigate(['/authentication/sign-in']);
        // }
        return false;
    }
}

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        console.log('AdminGuard')

        const users = JSON.parse(localStorage.getItem('accessUser'));
        if (users && (users || {}).accountType === 'admin') return true;
        if (users && (users || {}).accessToken && (users || {}).accountType !== 'admin') {
            this.router.navigate(['user-questions']);
            return false;
        }
        this.router.navigate(['groups']);
        return false;
    }
}
