
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  

  private bProfile  = new BehaviorSubject('');
  bProfileData = this.bProfile.asObservable();

  constructor(public snackBar: MatSnackBar ) {}

  config: MatSnackBarConfig = {
    duration: 3000000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  }

  success(msg:string) {
    this.config['panelClass'] = ['success', 'notification']
    this.snackBar.open(msg,'', this.config)
  }

  error(msg:string) {
    this.config['panelClass'] = ['error', 'notification']
    this.snackBar.open(msg,'', this.config)
  }

  setProfileBehaviour(payload){
    this.bProfile.next(payload);
  }
 


  //authentication user service



}