// Angular
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  /**
   * @name intercept
   * @param req
   * @param next
   * @return Observable<HttpEvent<any>>
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let _path = this.router.routerState.snapshot.url.split("/")[1], accessUser;

    console.log(_path)

    switch (_path) {
      case 'company-admin':
        accessUser = JSON.parse(localStorage.getItem('accessUser')) || JSON.parse(sessionStorage.getItem('accessUser'))
        break;

      case 'authentication':
        accessUser = JSON.parse(localStorage.getItem('accessUser')) || JSON.parse(sessionStorage.getItem('accessUser'))
        break;

      case 'admin':
        accessUser = JSON.parse(localStorage.getItem('accessSuperAdmin')) || JSON.parse(sessionStorage.getItem('accessSuperAdmin'))
        break;

      // case 'company-admin':
      //   accessUser = JSON.parse(localStorage.getItem('accessSuperAdmin')) || JSON.parse(sessionStorage.getItem('accessSuperAdmin'))
      //   break;

      default:
        accessUser = JSON.parse(localStorage.getItem('memberUser')) || JSON.parse(sessionStorage.getItem('memberUser'))
        break;
    }

    if (accessUser) {
      request = request.clone({
        url: (<any>environment).origin + request.url,
        headers: request.headers.set('Authorization', `Bearer ${accessUser.token}`)
      });
    } else {
      request = request.clone({ url: (<any>environment).origin + request.url });
    }
    return next.handle(request).pipe(
      tap((event) => { }, (error: any) => {
        if (error instanceof HttpErrorResponse && error.status == 401) {

          // handle 401 errors
          let _path = this.router.routerState.snapshot.url.split("/")[1], accessUser;

          switch (_path) {
            case 'main':
              this.router.navigate(['/company-admin/sign-in']);
              localStorage.removeItem("accessUser");
              sessionStorage.removeItem("accessUser");
              break;

            case 'admin':
              this.router.navigate(['/admin/sign-in']);
              localStorage.removeItem("accessSuperAdmin");
              sessionStorage.removeItem("accessSuperAdmin");
              break;

            default:
              this.router.navigate(['/company-member/sign-in']);
              localStorage.removeItem("memberUser");
              sessionStorage.removeItem("memberUser");
              break;
          }
        }
      }
      ));
  }
}
